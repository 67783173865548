<template>
    <div class="cooperativePartner_container">
        <!-- 标题部分 -->
        <div class="cooperativePartner_title">
            <h3>{{ titleAreaData.title }}</h3>
            <p class="common_title_p">
                {{ titleAreaData.describe }}
            </p>
            <div class="title_connect">
                <div class="title_line animated bounceInLeft"></div>
                <small class="animated bounceInRight" @click="immediately()"><span>立即咨询</span></small>
            </div>
        </div>

        <!-- <div class="common_superiority" v-if="superiorityList.length>0">

            <h2 animated bounceInLeft class="main_catchword_h2">硬件介绍</h2>
            <ul>
                <li v-for="(item, index) in superiorityList" :key="item.id">
                    <div class="viewRight">
                        <img :src="item.superiorityIcon"
                             
                             alt=""/>
                              <el-table
                                :data="item.tableData"
                                border
                                style="width: 100%">
                                <el-table-column
                                    prop="spec"
                                    label="规格"
                                    >
                                </el-table-column>
                                <el-table-column
                                    prop="parameter"
                                    label="参数">
                                </el-table-column>
                                
                                </el-table>
                    </div>
                </li>
            </ul>
        </div> -->
        <div class="common_superiority" v-if="superiorityList.length>0">
            <h2 animated bounceInLeft class="main_catchword_h2">硬件介绍</h2>
            <ul>
                <li v-for="(item, index) in superiorityList" :key="item.id">
                    <div class="viewLeft">
                        <img :src="item.superiorityIcon"
                             v-if="(index + 1) % 2 == 0"
                             alt=""/>
                        <strong v-if="(index + 1) % 2 != 0">{{
                                item.title
                            }}</strong>
                        <p v-if="(index + 1) % 2 != 0">{{ item.text}}</p>
                        <p v-if="(index + 1) % 2 != 0">{{ item.text1}}</p>
                        <p v-if="(index + 1) % 2 != 0">{{ item.text2}}</p>
                        <p v-if="(index + 1) % 2 != 0">{{ item.text3}}</p>
                        <p v-if="(index + 1) % 2 != 0">{{ item.text4}}</p>
                        <p v-if="(index + 1) % 2 != 0">{{ item.text5}}</p>
                        <p v-if="(index + 1) % 2 != 0">{{ item.text6}}</p>
                    </div>
                    <div class="viewRight">
                        <img :src="item.superiorityIcon"
                             v-if="(index + 1) % 2 != 0"
                             alt=""/>
                        <strong v-if="(index + 1) % 2 == 0">{{
                                item.title
                            }}</strong>
                        <p v-if="(index + 1) % 2 == 0">{{ item.text }}</p>
                    </div>
                </li>
            </ul>
        </div>

        <div class="common_superiority">
            <h2 animated bounceInLeft class="main_catchword_h2">参数介绍</h2>
            <ul>
                <li >
                   <el-table
                        :data="parameterList"
                        border
                        style="width: 100%">
                        <el-table-column
                            prop="spec"
                            label="规格"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="parameter"
                            label="参数">
                        </el-table-column>
                    </el-table>
                </li>
            </ul>
        </div>

        <div class="homeBottomPart">
            <common-BottomPart
                :emailPhoneInfo="emailPhone"
                :codeInfo="codeQR"
            ></common-BottomPart>
        </div>
        <!-- 左侧悬浮 -->
        <div class="floatingWindow">
            <common-floatWin></common-floatWin>
        </div>
    </div>
</template>

<script>
import commonProduct from '../../../components/productRecommendation/recommendation.vue'
import commonBottomPart from '../../../components/bottomPart/bottomPart.vue'
import commonFloatWin from '../../../components/floatingWindow/floatingWindow.vue'

export default {
    components: {commonProduct, commonBottomPart, commonFloatWin},

    mounted() {
        this.initBaseInfoList()
    },

    data() {
        return {
            titleAreaData: {
                title: 'x机加进销存和生产管理',
                describe: '无纸化管理，生产透明，库存清晰，数据透明，提升企业经营决策效率'
            },

             

            lowCodeList: [
                {
                    id: '1',
                    title: '中小企业进销存+小工单',
                    superiorityIcon: require('../../../assets/img/home/order.png')
                },
            ],

            superiorityList: [
                {
                    id: '1',
                    title: '第一个图片',
                    text: '一目了然',
                    superiorityIcon: require('../../../assets/img/hardware/hmi1.png'),
                },
                {
                    id: '2',
                    title: '第二个图片',
                    text: '一目了然',
                    superiorityIcon: require('../../../assets/img/product/application/task/xgd4.png'),
                },
                {
                    id: '3',
                    title: '定制流程',
                    text: '1: 沟通确定方案可行性',
                    text1: '2: 初步样品报价及交期',
                    text2: '3: 签订商务合同',
                    text3: '4: 付款',
                    text4: '5: 设计图纸',
                    text5: '6: 确认OK之后就按图纸生产，确认不行的话按照要求重新修改，确实不行的话就退回全款',
                    text6: '7: 如确认OK的话，那就生产按规定时间内发货',
                    superiorityIcon: require('../../../assets/img/hardware/hmi2.png'),
                },
                
            ],

            parameterList: [
                {spec:"尺寸",parameter:"12寸、15寸、17寸"},
                {spec:"分辨率",parameter:"1024×768、1024×768、1280×1024"},
                {spec:"cpu",parameter:"Intel 赛扬J1900四核2.0GHz CPU"},
                {spec:"芯片组",parameter:"Intel Bay trail SOC芯片组"},
                {spec:"显卡",parameter:"Intel HD Graphics(Sandy Bridge)"},
                {spec:"内存",parameter:"4GB DDR3"},
                {spec:"存储",parameter:"128G SSD固态硬盘"},
                {spec:"网络",parameter:"网络	RJ45网口,支持网络唤醒和PXE无盘启动功能.WiFi"},
                {spec:"音频",parameter:"支持音频输出(绿色)、支持麦克风输入(红色)"},
                {spec:"USB",parameter:"1 路 USB 3.0接口（蓝色组下面USB接口）3路USB2.0接口"},
                {spec:"显示",parameter:"2 路 RS232接口（可升级为6路"},
                {spec:"按键",parameter:"M12金属开关机按键"}
            ],
        }
    },

    methods: {
        initBaseInfoList() {
            this.productList = this.$store.state.pubInfo.recommendList
            this.emailPhone = this.$store.state.pubInfo.baseInfo
            this.codeQR = this.$store.state.pubInfo.codeList
        },

        immediately() {
            this.$router.push({name: 'Immediately'})
        },
    }
}
</script>

<style lang="less" scoped>
.common_superiority {
    width: 1282PX;
    margin: 50px auto;
    position: relative;

    ul {
        width: 100%;
        margin: 0 auto;
        margin-top: 80px;
        //   border-radius: 10px;
        //   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }

    ul li {
        width: 90%;
        margin-right: 1%;
        z-index: 99;
        padding: 30px 5%;
        background: #fff;
        display: flex;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        margin-top: 10px;

        .viewLeft,
        .viewRight {
            padding: 0px 9%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            img {
                width: 520PX;
                //   height: 300PX;
                border-radius: 5px;
                display: block;
                justify-content: space-between;
            }

            strong {
                color: #333;
                font-weight: 400;
                margin-top: 10px;
                font-size: 22PX;
                //letter-spacing: 2px;
            }

            p {
                font-size: 15PX;
                text-indent: 2rem;
                line-height: 1rem;
                //letter-spacing: 2px;
                color: #5e6d82;
            }
        }
    }
}

.container_architecture {
    //   height: 840PX;
    width: 1915PX;
    margin: 50px auto;

    img {
        // height:730PX;
        width: 1282PX;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
}

.main_catchword_h2 {
    font-size: 30PX;
    font-weight: 600;
    text-align: center;
    color: #000;
    margin-bottom: 70PX;
    margin-top: 80PX;
}

.cooperativePartner_container {
    color: #fff;
    position: relative;
    background: #fff;
}

.cooperativePartner_title {
    color: #fff;
    height: 660px;
    background: url('../../../assets/img/titlebackground/titleB30.jpg') no-repeat;
    background-size: cover;
    text-align: center;
    position: relative;
}

h3 {
    margin: 0;
    font-size: 60px;
    line-height: 200%;
    // font-weight: bold;
    padding-top: 200px;
    font-size: 40px;
    font-weight: normal;
}

.common_title_p {
    width: 50%;
    line-height: 200%;
    padding: 0 25%;
    font-size: 22px;
    //letter-spacing: 2px;
}

.title_line {
    width: 5%;
    height: 1px;
    background: #2db5a3;
    margin: 7.3% 0 0 61%;
}

small {
    font-size: 18px;
    position: absolute;
    top: 70%;
    left: 67%;
    cursor: pointer;
    padding: 10px 20px;
    background: #2db5a3;
    border-radius: 4px;
}

span {
    display: inline-block;
    font-size: 18px;
    color: #fff;
}

//优势
.cooperativePartner_superiority {
    position: relative;
    height: 500px;
    padding: 30px 15%;
    text-align: center;

    .main_catchword_h2 {
        font-size: 28px;
        font-weight: 400;
        //letter-spacing: 10px;
        color: #666;
        //letter-spacing: 0.125rem;
    }

    ul {
        color: black;
        padding: 100px auto;
        margin-left: 100px;

        li {
            width: 21%;
            height: 240px;
            float: left;
            padding: 5px 10px;
            text-align: center;
            margin-top: 80px;
            margin-right: 5px;
            z-index: 99;
            border-radius: 5px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

            img {
                width: 100px;
                height: 100px;
                display: block;
                margin: 0 0 20px 38%;
            }

            strong {
                font-size: 20px;
            }

            p {
                color: #666666;
            }
        }
    }
}

//合作伙伴
.cooperativePartner {
    margin: 10px 82px;
    height: 500px;

    .cooperativePartner_container {
        width: 60%;
        margin: 0 300px 0 370px;
    }

    .main_catchword_h2 {
        font-size: 28px;
        font-weight: 400;
        //letter-spacing: 10px;
        color: #666;
        text-align: center;
        margin-bottom: 40px;
    }

    .cooperativePartner-list {
        overflow: hidden;
        line-height: 18px;
        list-style-type: none;
        float: left;
    }

    .cooperativePartner-item {
        width: 150px;
        float: left;
        height: 120px;
    }

    img {
        // border-left: 1px dotted #ccc;
        // margin: 20px;
        width: 128px;
        height: 110px;
    }

    &:first-child {
        img {
            border-left: 0;
        }
    }
}

.productRecommendation {
    height: 380px;
}

.homeBottomPart {
    height: 420PX;
}

/* 小屏幕（平板，小于等于768px） */
// @media only screen and (max-width: 768px) and (min-width: 414px) {
@media (max-width: 768px) {
    .cooperativePartner_container {
        margin: 100px auto;
        // z-index: 2000px;
    }

    // 新闻中心标题
    .cooperativePartner_title {
        height: 77rem;

        h3 {
            font-size: 100px;
            padding-top: 26.5rem;
        }

        p {
            width: 84%;
            padding: 3.25rem 8%;
            font-size: 3.5rem;
        }

        .title_connect {
            // width: 110rem;
            position: relative;
            display: flex;

            .title_line {
                width: 10rem;
                height: 0.0625rem;
                margin: 10% 0 0 58%;
                display: none;
            }

            small {
                // width: 30rem;
                font-size: 4.125rem;
                border-radius: 1.25rem;
                top: 56%;
                // display: none;

                span {
                    font-size: 4.125rem;
                    font-weight: 300;
                }
            }
        }
    }
    .main_catchword_h2 {
        font-size: 4.75rem !important;
        font-weight: bold;
        margin-bottom: 6rem !important;
        // color: #4b4646;
        //letter-spacing: 0.125rem;
        margin-top: 14rem !important;
    }

    //优势
    .cooperativePartner_superiority {
        height: 90.25rem;
        padding: 2rem;

        .main_catchword_h2 {
            font-size: 5.75rem !important;
            font-weight: bold;
            color: #4b4646;
            //letter-spacing: 0.125rem;
        }

        ul {

            li {
                width: 44%;
                height: 25rem;
                padding: 0.3125rem 2rem;

                img {
                    width: 7.25rem;
                    height: 7.25rem;
                    display: block;
                    margin: 0 0 1.25rem 43%;
                }

                strong {
                    font-size: 4.25rem;
                }

                p {
                    font-size: 2.5rem;
                }
            }
        }
    }

    //合作伙伴
    .cooperativePartner {
        height: 140rem;
        margin: 0.625rem 0.125rem;

        .cooperativePartner_container {
            width: 86%;
            margin: 3rem 16rem;
            text-align: center;
        }

        .main_catchword_h2 {
            font-size: 5.75rem !important;
            font-weight: bold;
            color: #4b4646;
            //letter-spacing: 0.125rem;
        }

        .cooperativePartner-list {
            width: 32%;

        }

        .cooperativePartner-item {
            width: 22.375rem;
            float: left;
            height: 17.5rem;
        }

        img {
            width: 18.375rem !important;
            height: 17.5rem !important;
        }
    }

    .productRecommendation {
        height: 179.75rem;
    }

    .floatingWindow {
        // height: 60rem;
    }

    .container_architecture {
        height: 54rem;
        width: 100%;
        margin: 13rem auto;
        .main_catchword_h2{
            margin-top: 14rem !important;
        }
        img{
            height:51rem;
            width:calc(100% - 10rem);
            border-radius: 10px;
            object-fit: contain;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }
    }

    .common_superiority {
    // height: 1900px;
        width: 95%;
        margin: 50px auto;
        position: relative;

        ul {
            width: 100%;
            margin: 0 auto;
            margin-top: 30px;
            //   border-radius: 10px;
            //   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }

        ul li {
            width: 90%;
            margin-right: 1%;
            z-index: 99;
            padding: 30px 5%;
            background: #fff;
            display: flex;
            border-radius: 10px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            margin-top: 10px;

            .viewLeft,
            .viewRight {
                padding: 0px 9%;
                display: flex;
                justify-content: space-around;
                align-items: flex-start;
                flex-direction: column;

                img {
                    width: 520px;
                    //   height: 300PX;
                    border-radius: 5px;
                    display: block;
                    justify-content: space-between;
                }

                strong {
                    color: #333;
                    font-weight: 400;
                    margin-top: 1.6rem;
                    font-size: 3.3rem;
                    //letter-spacing: 2px;
                }

                p {
                    font-size: 2.3rem;
                    text-indent: 2rem;
                    line-height: 3.6rem;
                    //letter-spacing: 2px;
                    color: #5e6d82;
                }
            }
        }
    }
    ::v-deep .el-table .cell {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-all;
        font-size: 2.3rem;
        line-height: 2.4375rem;
        padding-right: 0.625rem;
    }
}

//  最小型号（小于414px）
@media (max-width: 414px) {

}
</style>
